<template>
  <form class="modal-inner-container" @submit.prevent="confirmDisabling">
    <div class="w-full">
      <div class="text-n-4xl font-bold">
        {{$t('security.twofa.disable_twofa')}}
      </div>

      <div class="text-n-md font-medium mt-n-5xl">
        {{$t('security.twofa.confirmation_mail')}}
      </div>

      <input
        @keypress="preventNonNumericalInput"
        class="v3 mt-n-4xl no-arrows"
        data-lpignore="true"
        placeholder="000000"
        ref="secretRef"
        data-testid="input-secret"
        type="number"
        v-model="secret"
      />

      <div class="warning-container">
        {{$t('security.twofa.warning')}}
      </div>
    </div>

    <div class="w-full mt-s64">
      <ButtonV2
        :inactive="isLoading || !secret"
        :label="$t('security.twofa.confirm_disabling')"
        version="destructive"
        testId="btn-disabling"
        submit
        wide
      />
      <ButtonV2
        @onClick="resendEmail"
        :label="$t('security.twofa.resend_email')"
        testId="btn-resend"
        class="mt-n-2xl"
        version="secondary"
        wide
      />
    </div>
  </form>
</template>

<script>
  import TWO_FACTOR_AUTH_DISABLE_STEP_TWO from '@/graphql/mutations/TwoFactorAuthDisableStepTwo.gql';
  import ButtonV2 from '@/stories/misc/ButtonV2';

  export default {
    name: 'DisableTwoFactorAuthentication',
    components: {
      ButtonV2,
    },

    async mounted() {
      await this.$store.dispatch('events/track', {
        event: 'TWO_FACTOR_DISABLE_VERIFY_EMAIL_CODE_VIEWED',
      });

      this.$refs.secretRef.focus();
    },

    data() {
      return {
        secret: '',
        isLoading: false,
      };
    },

    methods: {
      async confirmDisabling() {
        try {
          this.isLoading = true;

          await this.apolloApiCall({
            mutation: TWO_FACTOR_AUTH_DISABLE_STEP_TWO,
            variables: {
              secret: this.secret,
            }
          });

          const currentUser = { ...this.$store.state.api.current_user };

          this.$store.commit('api/setState', { stateName: 'current_user', stateData: { ...currentUser, user_info: { ...currentUser.user_info, twofa_enabled: false }, } });

          return this.showModal('TwoFactorAuthenticationDisabled');
        } catch (err) {
          await this.showError(err);
        } finally {
          this.isLoading = false;
        }
      },
      resendEmail() {
        this.showModal('VerifyAuthentication');
      },
    },

    watch: {
      secret(newValue, oldValue) {
        if (newValue.length > 6) {
          this.secret = oldValue;
        }
      },
    }
  };
</script>
